import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import HeroImage from '../components/heroImage'
import BackgroundTextSplit from '../components/backgroundTextSplit.js'
// import { Carousel } from "react-responsive-carousel"
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
// import 'pure-react-carousel/dist/react-carousel.es.css';

import Layout from "../components/layout"

const ImageContainerAnim = keyframes`
  0% {
    width: 0%;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ImageAnim = keyframes`
  0% {
    transform: scale(1.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`
const slideDown = keyframes`
  0% {
  transform: translateY(-30px);
  opacity: 0;
  }
  100% {
  transform: translateY(0px);
  opacity: 0.4;
  }
`
const LayerReveal = styled.div`
width: 0%;
height: 0%;


`
const Main = styled.div`
  margin: auto;
  width: 70%; 
  height: 50em;
  overflow: hidden;  
`
const SecondaryContainer = styled.div`
  float: left; 
  width: 100%;
  height: 100%; 
  margin: 10em auto; 
  overflow: hidden;
`
const ImageContainer = styled.div`
  float: left; 
  width: 100%;
  height: 100%; 
  margin: auto;
  opacity: 0;
  background-color: #dee2e4;
  background-opacity: 0;
  animation: ${ImageContainerAnim} cubic-bezier(.12, 1, .25, 1) 2.3s forwards; 
  animation-delay: 0.4s;
  overflow: hidden;
`

const Image = styled.div`
  height:50em;
  background-image: url("https://images.unsplash.com/photo-1554771129-5c8ecd59a14e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80");
  background-size: cover;
 transition: transform 0.4s ease-in-out;
  animation: ${ImageAnim} cubic-bezier(.12, 1, .25, 1) 2.3s;

  &:hover {
    transform: scale(1.03);
  }
`

const Text = styled.h1`
  font-family: -apple-system,BlinkMacSystemFont,"Segoe      UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-size: 1em;
  font-weight: 400;
  opacity: 0;
  animation: ${slideDown} cubic-bezier(.12, 1, .25, 1) 2.3s forwards; 
  animation-delay: 0.2s;
`


export default class Photos extends Component {
	render () {
		return (
    <Layout>
		<div>
    <Main>
    <BackgroundTextSplit text="Photos" />
			<SecondaryContainer>

			</SecondaryContainer>
		</Main>
    <LayerReveal></LayerReveal> 
    </div>
    </Layout>
		)
	}
}